import React from "react";
import { RichText } from "prismic-reactjs";
import FeaturedContent from "./featured-content-slice";
import ContentContainer from "../container/content-container";
import Reviews from "../new-shop-page/review-slice";
import { linkResolver } from "../../utils/linkResolver";
import { Box, Text } from "theme-ui";
import styles from "./landing.styles";

type PropsType = {
  content: any;
};

const LandingSliceZone: React.FC<PropsType> = ({ content }) => {
  const blogContent = content.map((slice: any, index: number) => {
    /* console.log(slice.slice_type); */
    if (!slice) return null;
    switch (slice.slice_type) {
      case "text":
        const text = RichText.render(
          slice.primary.text_block.raw,
          linkResolver
        );
        return (
          <ContentContainer width="100%" key={`slice-${index}`}>
            <Box sx={styles.container}>
              <div className="body-text article-landing">{text}</div>
            </Box>
          </ContentContainer>
        );
      case "divider":
        if (slice.primary.line)
          return (
            <span key={`slice-${index}`} className="divider article-landing" />
          );
      case "featured_articles":
        if (slice.items.length >= 2)
          return (
            <FeaturedContent
              items={slice.items.map((item: any) => ({
                title: item.title,
                fluidImage: item.image.fluid,
                link: item.link.url,
              }))}
              key={`slice-${index}`}
            />
          );
      case "reviews":
        if (slice.items.length)
          return <Reviews content={slice} key={`slice-${index}`} landing />;
      default:
        return null;
    }
  });
  return <div>{blogContent}</div>;
};

export default LandingSliceZone;
