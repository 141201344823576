import React, { FC } from "react";
import PrimaryLayout from "../components/layout/primary/primary";
import { graphql } from "gatsby";
import ArticleLanding from "../components/blog/article-landing-page";
import SEO from "../components/seo";

export const query = graphql`
  query {
    prismicArticleLandingPage(prismicId: { eq: "YmGFjxAAACIARhub" }) {
      data {
        body {
          ... on PrismicArticleLandingPageDataBodyDivider {
            slice_type
            primary {
              line
            }
          }
          ... on PrismicArticleLandingPageDataBodyFeaturedArticles {
            slice_type
            items {
              title
              link {
                url
              }
              image {
                fluid(maxWidth: 600) {
                  ...GatsbyImgixFluid_noBase64
                }
              }
            }
          }
          ... on PrismicArticleLandingPageDataBodyReviews {
            slice_type
            items {
              name
              text {
                raw
              }
            }
          }
          ... on PrismicArticleLandingPageDataBodyText {
            slice_type
            primary {
              text_block {
                raw
              }
            }
          }
        }
        metadescription {
          text
        }
        slider
        photo_slider {
          gallery_image {
            fluid(maxWidth: 680) {
              ...GatsbyImgixFluid_noBase64
            }
          }
        }
      }
    }
  }
`;

interface VenuePageProps {
  data: {
    prismicArticleLandingPage: any;
  };
}

const VenuePage: FC<VenuePageProps> = ({ data }) => {
  const content = data.prismicArticleLandingPage.data.body;
  const gallery = data.prismicArticleLandingPage.data.photo_slider;
  const slider = data.prismicArticleLandingPage.data.slider;
  const description = data.prismicArticleLandingPage.data.metadescription;

  return (
    <>
      <PrimaryLayout>
        <SEO title="Venue" description={description} />
        <ArticleLanding slider={slider} content={content} gallery={gallery} />
      </PrimaryLayout>
    </>
  );
};
export default VenuePage;
