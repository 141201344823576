import React from "react";
import { Box } from "theme-ui";
import styles from "./BlogPost.style";
import ContentContainer from "../container/content-container";
import PhotoSlider from "../photo-slider/photo-slider";
import LandingSliceZone from "../landing-slice-zone/landing-slice-zone";

type PropsType = {
  content: any;
  gallery: any;
  slider: boolean;
};
const ArticleLanding: React.FC<PropsType> = ({ content, gallery, slider }) => {
  return (
    <Box sx={styles.articleLandingWrapper}>
      {slider && (
        <>
          <Box p={["30px", 4]} />
          <ContentContainer width="100%">
            <PhotoSlider images={gallery} slice />
          </ContentContainer>
        </>
      )}
      <Box sx={styles.content}>
        {content.length && <LandingSliceZone content={content} />}
      </Box>
    </Box>
  );
};
export default ArticleLanding;
