const styles = {
  container: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    "flex-direction": "column",
    padding: "0 60px 40px 60px",
    "@media only screen and (max-width: 590px)": {
      padding: "0 40px 20px 40px",
    },
    a: {
      textDecoration: "none",
    },
    p: {
      marginTop: "0",
      marginBottom: "25px",
      fontSize: ["16px", "18px", "20px"],
      fontWeight: 500,
      color: "cgTeal",
      letterSpacing: "0.8px",
      lineHeight: "1.8em",
    },
    "p, h1, h2, h3, h4, h5, h6": {
      minHeight: "1em",
    },
    ol: {
      marginTop: "0",
      marginBottom: "25px",
      fontSize: ["16px", "18px", "20px"],
      fontWeight: 500,
      color: "cgTeal",
      letterSpacing: "0.8px",
      lineHeight: "1.8em",
    },
  },
  wrapper: {
    width: "100%",
    display: "flex",
    "flex-direction": "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    a: {
      textDecoration: "none",
    },
    p: {
      marginTop: "0",
      marginBottom: "25px",
      fontSize: "18px",
      lineHeight: "29px",
      "@media only screen and (min-width: 768px)": {
        fontSize: "19px",
      },
    },
    "p, h1, h2, h3, h4, h5, h6": {
      minHeight: "1em",
    },
    li: {
      marginBottom: "5px",
      fontSize: "19px",
      lineHeight: "29px",
    },
    img: {
      maxWidth: "100%",
      marginBottom: "20px",
    },
    h3: {
      marginBottom: "2rem",
    },
    ".category": {
      color: "#D79A2B",
      alignItems: "center",
      marginBottom: "20px",
      fontSize: "18px",
      svg: {
        height: "20px",
        marginRight: "8px",
        marginLeft: "-4px",
      },
    },
    ".hidden": {
      height: "17px",
    },
  },
  wrapperArticle: {
    width: "100%",
    display: "flex",
    "flex-direction": "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: "20px",
    a: {
      textDecoration: "none",
    },
    p: {
      marginTop: "0",
      marginBottom: "25px",
      fontSize: "18px",
      lineHeight: "29px",
      "@media only screen and (min-width: 768px)": {
        fontSize: "19px",
      },
    },
    "p, h1, h2, h3, h4, h5, h6": {
      minHeight: "1em",
    },
    li: {
      marginBottom: "5px",
      fontSize: "19px",
      lineHeight: "29px",
    },
    img: {
      maxWidth: "100%",
      marginBottom: "20px",
    },
    h3: {
      marginBottom: "2rem",
    },
    ".hidden": {
      height: "17px",
    },
  },
  category: {
    display: "flex",
    width: "100%",
    "flex-direction": "row",
    justifyContent: "flex-start",
    color: "#D79A2B",
    alignItems: "flex-start",
    marginBottom: "20px",
    marginTop: "20px",
    fontSize: "18px",
    "@media only screen and (max-width: 590px)": {
      marginTop: "40px",
    },
    svg: {
      height: "20px",
      marginRight: "8px",
      marginLeft: "-4px",
    },
  },
  imageWrap: {
    width: "100%",
    maxWidth: "1000px",
    margin: "0 auto",
    ".gatsby-image-wrapper": {
      marginBottom: "20px",
      height: "360px",
      "@media only screen and (min-width: 768px)": {
        height: "500px",
        marginBottom: "35px",
      },
      "@media only screen and (min-width: 1100px)": {
        height: "630px",
      },
    },
  },
  slices: {
    width: "100%",
  },
  headerContent: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "@media only screen and (max-width: 590px)": {
      flexDirection: "column",
    },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    padding: "0 10px 0 0",
    "&.blog": {
      display: "none",
    },
    "@media only screen and (max-width: 1200px)": {
      display: "flex",
    },
    "@media only screen and (max-width: 890px)": {
      display: "flex",
    },
    "@media only screen and (max-width: 590px)": {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      margin: "0 auto",
    },
  },
  content: {
    width: "100%",
    margin: "0 auto",
    maxWidth: "1000px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: ".8rem",
    "@media only screen and (min-width: 768px)": {
      paddingTop: "1.5rem",
    },
    p: {
      lineHeight: "29px",
    },
    ".divider": {
      paddingTop: ["10px", "20px"],
      marginBottom: ["30px", "40px"],
      display: "block",
      borderBottom: "3px solid #054752",
      "&.article-landing": {
        maxWidth: "1020px",
        px: "25px",
        margin: "0 10px 30px",
        "@media only screen and (min-width: 1115px)": {
          margin: "0 auto 30px",
        },
      },
    },
    ".content-image": {
      "&.gatsby-image-wrapper": {
        margin: "20px 0 40px",
        height: "auto",
        "@media only screen and (min-width: 768px)": {
          margin: "30px 0 55px",
        },
      },
    },
    ".body-text": {
      a: {
        color: "#F69D0D",
        "word-wrap": "break-word",
        //fontWeight: 700,
        textDecoration: "underline",
        "&:hover, &:focus": {
          color: "#F69D0D",
        },
      },
      h2: {
        fontSize: "1.7rem",
        "@media only screen and (min-width: 768px)": {
          fontSize: "2rem",
        },
      },
      h3: {
        fontSize: "1.53rem",
        lineHeight: "1.1em",
        "@media only screen and (min-width: 768px)": {
          fontSize: "1.75rem",
        },
      },
      h4: {
        fontSize: "1.45rem",
        "@media only screen and (min-width: 768px)": {
          fontSize: "1.65rem",
        },
      },
      h5: {
        fontSize: "1.38rem",
        "@media only screen and (min-width: 768px)": {
          fontSize: "1.45rem",
        },
      },
      h6: {
        fontSize: "1.15rem",
        "@media only screen and (min-width: 768px)": {
          fontSize: "1.25rem",
        },
      },
      "h2, h3, h4, h5, h6": {
        color: "#054752",
        fontWeight: 400,
        marginTop: "0",
        marginBottom: ".5rem",
      },
      "p.block-img": {
        width: "100%",
        height: "auto",
      },
      "&.border": {
        borderLeft: "solid 2px #D79A2B",
        padding: "0 0 0 10px",
        "h2, h3, h4, h5, h6": {
          color: "#054752",
          fontWeight: 500,
          marginTop: "0",
          marginBottom: ".5rem",
        },
      },
    },
    ".embed": {
      padding: "20px 0 30px",
      "@media only screen and (min-width: 768px)": {
        padding: "30px 0 40px",
      },
      iFrame: {
        width: "100%",
        height: "280px",
        "@media only screen and (min-width: 560px)": {
          height: "400px",
        },
      },
    },
    ".map": {
      padding: "20px 0 30px",
      "@media only screen and (min-width: 768px)": {
        padding: "30px 30px 40px",
      },
      iFrame: {
        width: "100%",
        minHeight: "620px",
        "@media only screen and (max-width: 560px)": {
          minHeight: "450px",
        },
      },
    },
  },
  title: {
    color: "#054752",
    fontWeight: 600,
    margin: "0 0 19px",
    textAlign: "center",
    maxWidth: "750px",
    fontSize: "34px",
    "@media only screen and (min-width: 768px)": {
      fontSize: "42px",
    },
  },
  description: {
    color: "#054752",
    fontWeight: 500,
    fontSize: "18px",
    "text-align": "left",
    p: {
      maxWidth: ["100%", "90%", "85%"],
    },
  },
  author: {
    color: "#054752",
    fontWeight: 500,
    paddingBottom: "16px",
    fontSize: "16px",
    "text-align": "left",
    display: "block",
  },
  blogWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "flex-direction": "column",
    width: "100%",
    a: {
      textDecoration: "none",
    },
    p: {
      marginTop: "0",
      marginBottom: "25px",
      fontSize: "18px",
      lineHeight: "29px",
    },
    "p, h1, h2, h3, h4, h5, h6": {
      minHeight: "1em",
    },
  },
  blogContent: {
    width: "100%",
    
    "flex-direction": "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "@media only screen and (max-width: 590px)": {
      flexDirection: "column",
    },
  },
  leftContent: {
    width: "30%",
    "@media only screen and (max-width: 590px)": {
      width: "100%",
    },
  },
  rightContent: {
    width: "65%",
    padding: "0 0 0 20px",
    margin: "0 0 0 20px",
    "@media only screen and (max-width: 590px)": {
      width: "100%",
    },
  },
  wrapperSide: {
    display: "flex",
    width: "100%",
    "flex-direction": "row",
    justifyContent: "space-between",
  },
  wrapperAbout: {
    display: "flex",
    width: "100%",
    "flex-direction": "column",
    margin: "auto",
  },
  sideContent: {
    display: "flex",
    width: "20%",
    "flex-direction": "column",
    justifyContent: "flex-start",
    gap: "40px",
    "@media only screen and (max-width: 1200px)": {
      width: "100%",
    },
    "@media only screen and (max-width: 890px)": {
      width: "100%",
    },
    "@media only screen and (max-width: 590px)": {
      display: "none",
    },
  },
  sidebar: {
    display: "flex",
    width: "100%",
    "flex-direction": "row",
    margin: "20px auto",
    justifyContent: "center",
    gap: "40px",
    "&.hidden": {
      display: "none",
    },
    "@media only screen and (max-width: 1200px)": {
      "flex-direction": "row",
    },
    "@media only screen and (max-width: 890px)": {
      "flex-direction": "row",
    },
    "@media only screen and (max-width: 590px)": {
      "flex-direction": "column",
    },
  },
  leftBar: {
    display: "flex",
    width: "100%",
    "flex-direction": "column",
    margin: "20px 0",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "20px",
  },
  relatedHeading: {
    display: "flex",
    width: "90%",
    color: "#054752",
    fontWeight: 500,
    fontSize: "34px",
    fontFamily: "Merriweather",
    "text-align": "left",
    paddingBottom: "15px",
    borderBottom: "solid 3px #054752",
    "&.not-border": {
      borderBottom: "none",
    },
  },
  relatedPost: {
    margin: "0 20px 20px",
    width: "100%",
    "&:nth-of-type(odd)": {
      borderTop: "solid 3px #AE1485",
      borderBottom: "solid 3px #AE1485",
    },
    "&:nth-of-type(even)": {
      borderTop: "solid 3px #B6BE0D",
      borderBottom: "solid 3px #B6BE0D",
    },
    "@media only screen and (min-width: 768px)": {
      width: "43%",
      margin: "0 20px 20px",
    },
    "@media only screen and (min-width: 1100px)": {
      width: "100%",
      margin: "0 0 30px",
      paddingBottom: "12px",
    },
  },
  relatedLeft: {
    width: "100%",
  },
  articleLandingWrapper: {
    p: {
      marginTop: "0",
      marginBottom: "25px",
      fontSize: "18px",
      lineHeight: "29px",
      "@media only screen and (min-width: 768px)": {
        fontSize: "19px",
      },
    },
    "p, h1, h2, h3, h4, h5, h6": {
      minHeight: "1em",
    },
    li: {
      marginBottom: "5px",
      fontSize: "19px",
      lineHeight: "29px",
    },
    img: {
      maxWidth: "100%",
      marginBottom: "20px",
    },
    h3: {
      marginBottom: "2rem",
    },
  },
};

export default styles;
