const styles = {
  section: {
    padding: "30px 80px",
    margin: ["20px 0 20px", "20px 0 50px"],
    "@media only screen and (max-width: 590px)": {
      padding: "0 20px",
    },
    "&.article-landing": {
      margin: "20px 0",
    },
  },
  smallBanner: {
    "flex-direction": "column",
    justifyContent: "space-between",
    gap: "40px",
    /* "flex-wrap": "wrap", */
    "@media only screen and (min-width: 560px)": {
      "flex-direction": "row",
    },
  },
  overlayWrap: {
    "text-align": "center",
    position: "relative" as "relative",
    width: "100%",
    "@media only screen and (min-width: 560px)": {
      width: "49%",
    },
    ".gatsby-image-wrapper": {
      height: ["250px", "360px"],
      width: "100%",
      marginBottom: "20px",
    },
    img: {
      width: "100%",
      overflow: "hidden",
      marginBottom: "20px",
    },
    a: {
      color: "white",
      textDecoration: "none",
    },
    ".overlay": {
      position: "absolute" as "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background:
        "linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.0) 100%)",
      zIndex: 5,
    },
    ".text": {
      fontSize: ["35px", "45px"],
      letterSpacing: ".8px",
      width: "90%",
      transition: "all .3s ease",
      display: "block",
      zIndex: 10,
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
};

export default styles;
