import { Box, Flex, Text } from "theme-ui";
import { Link } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import ContentContainer from "../container/content-container";
import styles from "../new-shop-page/featured-two.style";

type Props = {
  items: Array<{
    link: string;
    fluidImage: FluidObject | FluidObject[];
    title: string;
  }>;
};

const FeaturedContentSlice: React.FC<Props> = ({ items }) => {
  return (
    <ContentContainer width="100%">
      <Box sx={styles.section} className="article-landing">
        <Flex sx={styles.smallBanner} className="featured-two">
          {items.map((item) => {
            return (
              <Box key={item.title} sx={styles.overlayWrap} className="side">
                <Link to={item.link}>
                  <Img fluid={item.fluidImage} alt={item.title} />
                  <Text className="text" as="h2">
                    {item.title}
                  </Text>
                  <div className="overlay" />
                </Link>
              </Box>
            );
          })}
        </Flex>
      </Box>
    </ContentContainer>
  );
};

export default FeaturedContentSlice;
